<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">添加分类</el-button>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" v-if="row.level !== 3" @click="handleCreateSon(row)">添加分类</el-button>
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        :title="`${form.id ? '编辑' : '添加'}分类`"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 :upload-error="uploadError"
                 :upload-delete="uploadDelete"
                 :upload-after="uploadAfter"
                 :upload-before="uploadBefore"
                 @submit="submit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {list, add, deleteClassify, view, update} from '@/api/shop/classify'
export default {
  name: "index",
  data(){
    return{
      tableData: [],
      showLoading: false,
      option:{
        title:'分类管理',
        titleSize:'h3',
        card: true,
        border: false,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: false, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: false, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            prop: 'name',
            align: 'left',
            width: 400,
          },
          {
            prop: 'image',
            align: 'left',
            type: 'img',
          }
        ]
      },

      dialogVisible: false,
      form: {
        id: '',
        name: '',
        image: '',
        sort: '',
        isShow: '',
        level: 1,
        parentId: 0
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: '分类名称',
            prop: 'name',
            maxlength: 10,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: true,
              message: "请输入分类名称",
              trigger: "blur"
            }]
          },
          {
            label: '分类图片',
            prop: 'image',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '建议尺寸：144*144',
            fileSize: 20 * 1024,
            span: 24,
            rules: [{
              required: true,
              message: "请上分类图片",
              trigger: "blur"
            }]
          },
          {
            label: '排序值',
            prop: 'sort',
            type: 'number',
            span: 24,
            maxRows: 999999,
            minRows: 1,
            labelTip: '越大越靠前',
            rules: [{
              required: true,
              message: "请输入排序值",
              trigger: "blur"
            }]
          },
          {
            label: '是否显示',
            prop: 'isShow',
            type: 'select',
            span: 24,
            labelTip: '前端是否显示此分类',
            dicData: [
              {
                label: '显示',
                value: 1
              },
              {
                label: '隐藏',
                value: 2
              }
            ],
            rules: [{
              required: true,
              message: "请选择是否显示",
              trigger: "blur"
            }]
          },

        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list().then(res => {
        this.showLoading = false;
        this.tableData = res.data;
      })
    },
    /* 添加 */
    handleCreate(){
      // console.log('添加');
      this.dialogVisible = true;
    },
    /* 添加分类 */
    handleCreateSon(row){
      console.log('添加分类', row.level);
      this.form.parentId = row.id;
      this.form.level = row.level + 1;
      this.dialogVisible = true;
    },
    /* 编辑 */
    async handleEdit(row){
      // console.log('编辑', row);
      const { data } = await view({id: row.id});
      this.form = {
        level: row.level,
        parentId: row.parentId,
        ...data
      }
      this.dialogVisible = true;
    },
    /* 删除 */
    handleDelete(row){
      console.log('删除', row);
      this.$confirm('是否删除此分类?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteClassify({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    /* 提交 */
    submit(form,done){
      // console.log('提交', form);
      if(form.id){
        update(form).then(res => {
          if(res.code === 200){
            this.$message.success('修改成功');
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm();
            }, 500);
            this.onLoad();
          }else{
            this.$message.error(res.msg);
            done();
          }
        }).catch(()=> done())
      }else{
        add(form).then(res => {
          if(res.code === 200){
            this.$message.success('添加成功');
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm();
            }, 500);
            this.onLoad();
          }else{
            this.$message.error(res.msg);
            done();
          }
        }).catch(()=> done())
      }
    },
    /* 关闭 */
    handleClose(){
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    /* 重置form */
    resetForm(){
      this.form = {
        id: '',
        name: '',
        image: '',
        sort: '',
        isShow: '',
        level: 1,
        parentId: 0
      }
    },
    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        column.propsHttp.name = res.path;
      }
    }
  }
}
</script>

<style scoped>

</style>