import {get, post, put,Delete} from "@/api/axios";

export const list = params => get('/web/shop/classify/list', params);

// /web/shop/classify/add post
export const add = params => post('/web/shop/classify/add', params);

// /web/shop/classify/delete/{id} Delete
export const deleteClassify = params => Delete('/web/shop/classify/delete/' + params.id, '');

// /web/shop/classify/view/{id} get
export const view = params => get('/web/shop/classify/view/' + params.id, '');

// /web/shop/classify/update put
export const update = params => put('/web/shop/classify/update', params);